<template>
  <menubar />
  <main-container></main-container>
</template>

<script>
import Menubar from "../../components/navigation/Top.vue";
import MainContainer from "../../components/secure/MainContainer.vue";

export default {
  components: {
    Menubar,
    MainContainer,
  },
};
</script>

<style>
html,
body,
#app {
  background: white;
}


</style>
